define(['jquery'], function($) {
  
  var _touchControls = {

    _config: {},
    /**
     * Set touch control events for each main navigation link
     */
    _nav: function() {

      var clickCount = [],
        parentScope = this,
        accountCount = true;
      this._config.$mainMenuLink.find(this._config.subMenuWrapper + ' a').on('click', function() {
        window.location = $(this).attr('href');
      });

      if (this._config.$navItemAccount) {
        this._config.$navItemAccount.find('.tool-tip-inner a').on('click', function() {
          parentScope._config.$navItemAccount.unbind('click');
          window.location = $(this).attr('href');
        });

        this._config.$navItemAccount.on('click', function() {
          if (accountCount) {
            accountCount = false;
            return false;
          }
        });
      }

      this._config.$mainMenuLink.each(function(i) {
        clickCount[i] = 0;
        $(this).on('click', function() {

          if ($(this).find(parentScope._config.subMenuWrapper).length < 1) {
            return;
          }
          if (clickCount[i] === 0) {
            $(this).find(parentScope._config.subMenuWrapper).show();
            $(this).addClass('active');
            clickCount = parentScope._resetOldClicks(clickCount);
            clickCount[i]++;
            return false;
          }

          $(this).find(parentScope._config.subMenuWrapper).hide();
          $(this).removeClass('active');
          return true;
        });
        clickCount = parentScope._resetOldClicks(clickCount);
        $(this).mouseleave(function() {
          $(this).find(parentScope._config.subMenuWrapper).hide();
          $(this).removeClass('active');
        });
      });
    },

    /**
     * Reset old clicks
     * @param oldClicks
     * @returns {array}
     */
    _resetOldClicks: function(oldClicks) {
      for (var i = 0; i < oldClicks.length; i++) {
        oldClicks[i] = 0;
      }
      return oldClicks;
    }

  };

  return {
    init: function(config) {
      _touchControls._config = config;
      _touchControls._nav();
    }
  };

});
